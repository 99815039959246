import {
  COHORT_COURSE_TYPES,
  cmsToSectionKeyMap
} from '@/features/CohortCoursePage/constants';
import {
  getCommunityDataByCode,
  getCommunityDataService
} from '@/services/communitiesService';

import config from '@/utility/config';
import { createClient } from 'contentful';
import { getCourseCardsData } from '@/services/courseService';
import { sectionIdToHompageComponentMap } from '@/features/CapLandingPage/constants';

export const CONTENTFUL_COMMUNITY_PRODUCT_TYPE_ID = 'communityProductPage';
export const CONTENTFUL_FREE_WORKSHOP_CONTENT_TYPE_ID = 'freeWorkshop';
export const CONTENTFUL_COHORT_COURSE_CONTENT_TYPE_ID = 'cohortCoursePage';
export const DB_COMMUNITY_CONTENT_TYPE_ID = 'communityPage';
export const CONTENTFUL_NASIO_HOME_PAGE_CONTENT_TYPE_ID =
  'nasIoCommunityHomePage';
export const CONTENTFUL_NASIO_INDIA_HOME_PAGE_CONTENT_TYPE_ID =
  'nasioIndiaHomepage';
export const CONTENTFUL_NASIO_ABOUT_US_PAGE_CONTENT_TYPE_ID =
  'nasIoAboutUs';
export const CONTENTFUL_NASIO_HOME_PAGE_V1_CONTENT_TYPE_ID =
  'nas-academy-landing-page-v1';
export const CONTENTFUL_HOMEPAGE_CONTENT_TYPE_ID = 'homepage';
export const CONTENTFUL_FEATURE_PAGE_CONTENT_TYPE_ID = 'nasIoFeaturePage';
export const CONTENTFUL_PRICING_PAGE_CONTENT_TYPE_ID = 'nasIoPricingPage';
export const CONTENTFUL_USE_CASE_PAGE_CONTENT_TYPE_ID = 'nasIoUseCasePage';
export const CONTENTFUL_CAP_LANDING_PAGE_CONTENT_TYPE_ID =
  'capLandingPage';

export const DB_EVENT_PUBLIC_PAGE_CONTENT_TYPE_ID = 'eventPublicPage';
export const DB_RESOURCE_PUBLIC_PAGE_CONTENT_TYPE_ID =
  'resourcePublicPage';

export const CONTENFUL_CM_TERMS_COMMON = 'community-manager-terms-common';
export const CONTENFUL_CM_TERMS_INDIA = 'community-manager-terms-india';
export const CONTENFUL_CM_TERMS_CONTENT_TYPE_ID =
  'nasIoCommunityManagerTerms';

const checkForLiveOnProdContentTypesList = [
  CONTENTFUL_COMMUNITY_PRODUCT_TYPE_ID,
  CONTENTFUL_FEATURE_PAGE_CONTENT_TYPE_ID,
  CONTENTFUL_USE_CASE_PAGE_CONTENT_TYPE_ID,
  CONTENTFUL_CAP_LANDING_PAGE_CONTENT_TYPE_ID
];

export const getContentfulClient = () => {
  const client = createClient({
    accessToken: config.contentfulAccessToken,
    space: config.contentfulSpaceId
  });

  return client;
};

const getCourseTypeFromCourseItem = (courseItem) =>
  courseItem?.sys?.contentType?.sys?.id || '';

export const getAllCommunityPageSlugsFromContentful = async () => {
  try {
    const client = getContentfulClient();
    const entries = await client.getEntries({
      limit: 300,
      order: 'sys.createdAt'
    });

    const allCommunityContentItems = entries.items.filter((entryItem) => {
      return [
        CONTENTFUL_FREE_WORKSHOP_CONTENT_TYPE_ID,
        CONTENTFUL_COMMUNITY_PRODUCT_TYPE_ID,
        CONTENTFUL_COHORT_COURSE_CONTENT_TYPE_ID,
        CONTENTFUL_FEATURE_PAGE_CONTENT_TYPE_ID,
        CONTENTFUL_USE_CASE_PAGE_CONTENT_TYPE_ID
      ].includes(entryItem.sys.contentType.sys.id);
    });

    const slugs = allCommunityContentItems
      .map((communityItem) => {
        if (config.isProdEnvironment) {
          // if course type is cohort, check if it should be live on prod. If not, then return '' else return slug;
          if (
            checkForLiveOnProdContentTypesList.includes(
              getCourseTypeFromCourseItem(communityItem)
            ) &&
            !communityItem.fields.isLiveOnProduction
          ) {
            return '';
          }
          return communityItem.fields.slug;
        } else {
          return communityItem.fields.slug;
        }
      })
      .filter((x) => !!x);

    return slugs;
  } catch (e) {
    console.warn(e);
    return [];
  }
};

export const getPageDataBySlugFromContentful = async (slug) => {
  try {
    const client = getContentfulClient();

    let start = Date.now();

    const promises = [
      CONTENTFUL_FREE_WORKSHOP_CONTENT_TYPE_ID,
      CONTENTFUL_COHORT_COURSE_CONTENT_TYPE_ID,
      CONTENTFUL_COMMUNITY_PRODUCT_TYPE_ID,
      CONTENTFUL_FEATURE_PAGE_CONTENT_TYPE_ID,
      CONTENTFUL_USE_CASE_PAGE_CONTENT_TYPE_ID,
      CONTENTFUL_CAP_LANDING_PAGE_CONTENT_TYPE_ID
    ].map((contentType) => {
      return client.getEntries({
        content_type: contentType,
        'fields.slug': slug
      });
    });
    const [
      freeWorkshopRes,
      cohortCourseRes,
      communityRes,
      nasIoFeaturePageRes,
      nasIoUseCasePageRes,
      capLandingPageRes
    ] = await Promise.all(promises);

    let end = Date.now();
    let execTimeSec = (end - start) / 1000;
    console.log(
      `\nTime to get data from contentful for ${slug} : ${execTimeSec} seconds`
    );

    let coursePageInfo = {
      templateContentType: '',
      templateData: null
    };

    if (cohortCourseRes.items.length > 0) {
      if (!Array.isArray(cohortCourseRes.items)) return null;

      const coursePageData = cohortCourseRes.items[0];
      const formattedCoursePageRes =
        formatCohortCoursePageRes(coursePageData);

      const { courseCode } = formattedCoursePageRes;
      start = Date.now();
      const dynamicCourseCardsData = await getDynamicCourseCardsData(
        courseCode,
        'suggestedCourses'
      );
      end = Date.now();
      execTimeSec = (end - start) / 1000;
      console.log(
        `\n[cohortCourseRes] Time to get data from getCourseCardsData(${config.apiStaticDataPath}/api/v1/course-card-data/?courseCodes=${courseCode}) for ${slug} : ${execTimeSec} seconds`
      );

      coursePageInfo = {
        templateContentType: CONTENTFUL_COHORT_COURSE_CONTENT_TYPE_ID,
        templateData: {
          ...formattedCoursePageRes,
          ...dynamicCourseCardsData
        }
      };
    }

    if (communityRes.items.length > 0) {
      if (!Array.isArray(communityRes.items)) return null;

      const communityPageData = communityRes.items[0];
      const formattedCommunityPageRes =
        formatCommunityPageRes(communityPageData);

      // Get Dynamic data for community page
      const { communityCode } = formattedCommunityPageRes;
      const communityData = await getCommunityDataByCode(communityCode);
      const { data, error } = communityData;
      if (!error) {
        formattedCommunityPageRes.communityData = data;
      }
      coursePageInfo = {
        templateContentType: CONTENTFUL_COMMUNITY_PRODUCT_TYPE_ID,
        templateData: formattedCommunityPageRes
      };
    }

    if (freeWorkshopRes.items.length > 0) {
      if (!Array.isArray(freeWorkshopRes.items)) return null;

      const coursePageData = freeWorkshopRes.items[0];
      const formattedCoursePageRes =
        formatFreeWorkshopCoursePageRes(coursePageData);

      const { courseCode } = formattedCoursePageRes;
      start = Date.now();
      const dynamicCourseCardsData = await getDynamicCourseCardsData(
        courseCode,
        'promoCourseData'
      );
      end = Date.now();
      execTimeSec = (end - start) / 1000;
      console.log(
        `\n[freeWorkshopRes] Time to get data from getCourseCardsData(${config.apiStaticDataPath}/api/v1/course-card-data/?courseCodes=${courseCode}) for ${slug} : ${execTimeSec} seconds`
      );

      coursePageInfo = {
        templateContentType: CONTENTFUL_FREE_WORKSHOP_CONTENT_TYPE_ID,
        templateData: {
          ...formattedCoursePageRes,
          ...dynamicCourseCardsData
        }
      };
    }

    if (capLandingPageRes.items.length > 0) {
      if (!Array.isArray(capLandingPageRes.items)) return null;

      const capLandingPageData = capLandingPageRes.items[0];
      const formattedCapLandingPageRes =
        formatCapLandingPageRes(capLandingPageData);
      // Get Dynamic data for community page
      const { communityCode } = formattedCapLandingPageRes;
      // We want to get the data by code
      const communityData = await getCommunityDataService(
        null,
        communityCode
      );

      const { data, error } = communityData;
      if (!error) {
        formattedCapLandingPageRes.communityData = data;
      } else {
        console.error('Error in getting community data', error);
      }
      coursePageInfo = {
        templateContentType: CONTENTFUL_CAP_LANDING_PAGE_CONTENT_TYPE_ID,
        templateData: formattedCapLandingPageRes
      };
    }

    if (nasIoFeaturePageRes.items.length > 0) {
      if (!Array.isArray(nasIoFeaturePageRes.items)) return null;
      coursePageInfo = {
        templateContentType: CONTENTFUL_FEATURE_PAGE_CONTENT_TYPE_ID,
        templateData: nasIoFeaturePageRes.items[0].fields
      };
    }

    if (nasIoUseCasePageRes.items.length > 0) {
      if (!Array.isArray(nasIoUseCasePageRes.items)) return null;

      coursePageInfo = {
        templateContentType: CONTENTFUL_USE_CASE_PAGE_CONTENT_TYPE_ID,
        templateData: nasIoUseCasePageRes.items[0].fields
      };
    }

    return coursePageInfo;
  } catch (e) {
    console.log('[ERROR] ', e, e.message);
    return {
      temelateContentType: '',
      templateData: null
    };
  }
};

const getDynamicCourseCardsData = async (
  courseCode,
  keyForStaticCards
) => {
  const { data } = await getCourseCardsData([courseCode]);
  if (data?.[courseCode]?.[keyForStaticCards]) {
    if (
      data?.[courseCode]?.[keyForStaticCards] &&
      data?.[courseCode]?.[keyForStaticCards].length > 0
    ) {
      const dynamicCourseCardData = data?.[courseCode]?.[
        keyForStaticCards
      ].reduce((acc, course) => {
        acc[course.code] = course;
        return acc;
      }, {});
      const creatorStaticCards =
        await getStaticCreatorCourseCardsForCourseCodes([
          ...Object.keys(dynamicCourseCardData)
        ]);

      return { dynamicCourseCardData, creatorStaticCards };
    }
  }
  return null;
};

const formatSectionsOrder = (orders) =>
  orders.map((item) => item.split(', '));

export const getCMSCompnentNameFromSectionId = (sectionId) => {
  if (sectionId.includes('_')) {
    // const [cmsSectionName, sectionIndex] = sectionId.split('_');
    const [cmsSectionName] = sectionId.split('_');
    return cmsToSectionKeyMap?.[cmsSectionName] || false;
  }
  return cmsToSectionKeyMap?.[sectionId] || false;
};

const formatCohortCoursePageRes = (coursePageData) => {
  const {
    slug,
    courseCode,
    coursePageType = COHORT_COURSE_TYPES.COHORT_COURSE,
    // Hide next course date countdown
    hideNextCourseDateCountdown = false,
    remindMeSectionProps = {
      cmsShowRemindMeBtnFlag: true,
      ctaLabel: 'Remind Me',
      desc: 'No problem! Drop your email here and we will remind you before the class starts',
      title: 'Not yet ready to commit?',
      numberOfDaysBeforeRemindMeShow: 10
    },
    isLiveOnProduction,
    feedbackCourseCode,
    deafultSignUpLabel,
    waitlistModalFormSectionProps,
    duplicateSectionsJson = {},
    sectionOrder,
    communityCode = '',
    ...restSections
  } = coursePageData.fields;

  const allSectionsJson = {
    ...restSections,
    remindMeSectionProps,
    ...duplicateSectionsJson
  };

  const restSectionsKeys = Object.keys(allSectionsJson);

  const initSectionProps = {
    userFeedbackSection: !!feedbackCourseCode
  };

  const sectionProps = restSectionsKeys.reduce((acc, cmsSectionKey) => {
    const sectionData = allSectionsJson[cmsSectionKey];

    if (sectionData) {
      const sectionId = getCMSCompnentNameFromSectionId(cmsSectionKey);
      if (!sectionId) {
        console.warn(
          `Unknown SectionId: ${cmsSectionKey} for slug ${slug}`
        );
      } else {
        acc[cmsSectionKey] = sectionData;
      }
    }

    return acc;
  }, initSectionProps);

  const orderedSectionKeys = sectionOrder
    .map((sectionId) => {
      const key = getCMSCompnentNameFromSectionId(sectionId);
      if (!key)
        console.warn(`Unknown SectionId: ${sectionId} for slug ${slug}`);
      return sectionId;
    })
    .filter(Boolean);

  const formattedTemplateData = {
    slug,
    courseCode,
    hideNextCourseDateCountdown,
    coursePageType,
    isLiveOnProduction,
    defaultCtaLabel: deafultSignUpLabel,
    feedbackCourseCode,
    waitlistModalFormProps: waitlistModalFormSectionProps,
    orderedSectionKeys,
    sectionOrder,
    remindMeSectionProps,
    sectionProps,
    communityCode
  };

  return formattedTemplateData;
};

const formatCapLandingPageRes = (communityPageData) => {
  // console.log('communityPageData', communityPageData);
  const {
    slug,
    communityCode,
    bookACallCtaLink,
    applyCtaLink,
    secondaryCtaLabel,
    secondaryCtaLink,
    pageStatus,
    sectionOrder,
    // mobileSectionsOrder,
    // desktopSectionsOrder,
    ctaLabel,
    isLiveOnProduction,
    isOnWaitlist,
    // duplicationSections,
    customCtaLabel,
    customCtaSupportText,
    navbarVariant,
    // moveToNasIo, // This is a nasacademy field to make sure that it redirects to this portal
    // duplicateSections,
    ...sectionProps
  } = communityPageData.fields;

  const formattedSectionProps = Object.keys(sectionProps).reduce(
    (acc, cmsSectionKey) => {
      const sectionData = sectionProps[cmsSectionKey];
      const formattedSectionKey =
        sectionIdToHompageComponentMap?.[cmsSectionKey];

      if (formattedSectionKey) {
        acc[formattedSectionKey] = sectionData;
      } else {
        console.error(
          'Error in formatting section key',
          formattedSectionKey,
          cmsSectionKey
        );
      }
      return acc;
    },
    {}
  );

  const formattedSectionOrder = sectionOrder.map(
    (sectionId) => sectionIdToHompageComponentMap[sectionId]
  );
  return {
    slug,
    customCtaLabel: customCtaLabel || null,
    secondaryCtaLabel: secondaryCtaLabel || null,
    secondaryCtaLink: secondaryCtaLink || null,
    customCtaSupportText: customCtaSupportText || null,
    applyCtaLink: applyCtaLink || '',
    bookACallCtaLink: bookACallCtaLink || '',
    communityCode: communityCode || null,
    isOnWaitlist: isOnWaitlist || true,
    isLiveOnProduction: isLiveOnProduction || false,
    mobileSectionsOrder: formattedSectionOrder,
    desktopSectionsOrder: formattedSectionOrder,
    ctaLabel: ctaLabel || null,
    navbarVariant: navbarVariant || 'Nas IO',
    pageStatus,
    sectionProps: formattedSectionProps
  };
};

const formatCommunityPageRes = (communityPageData) => {
  const {
    slug,
    communityCode,
    mobileSectionsOrder,
    desktopSectionsOrder,
    ctaLabel,
    isLiveOnProduction,
    isOnWaitlist,
    ...sectionProps
  } = communityPageData.fields;

  const isFreeCommunity = communityPageData.fields?.isFreeCommunity;

  const formattedSectionsOrder = formatSectionsOrder([
    mobileSectionsOrder,
    desktopSectionsOrder
  ]);

  return {
    slug,
    communityCode,
    isOnWaitlist,
    isLiveOnProduction,
    isFreeCommunity,
    mobileSectionsOrder: formattedSectionsOrder[0],
    desktopSectionsOrder: formattedSectionsOrder[1],
    ctaLabel,
    sectionProps
  };
};

const formatFreeWorkshopCoursePageRes = (coursePageData) => {
  const {
    slug,
    courseCode,
    mobileSectionsOrder,
    desktopSectionsOrder,
    ...sectionProps
  } = coursePageData.fields;

  const formattedSectionsOrder = formatSectionsOrder([
    mobileSectionsOrder,
    desktopSectionsOrder
  ]);

  const formattedTemplateData = {
    slug,
    courseCode,
    mobileSectionsOrder: formattedSectionsOrder[0],
    desktopSectionsOrder: formattedSectionsOrder[1],
    sectionProps
  };

  return formattedTemplateData;
};

export const getContenfulDataByContentType = async (
  contentTypeKey,
  fieldKey
) => {
  try {
    const client = getContentfulClient();

    const params = {
      content_type: contentTypeKey
    };

    if (fieldKey) {
      params['fields.name'] = fieldKey;
    }

    const pagePropsRes = await client.getEntries(params);

    if (!pagePropsRes?.items?.[0]?.fields) {
      throw new Error('Invalid response from contentful ', pagePropsRes);
    }

    return pagePropsRes?.items?.[0]?.fields;
  } catch (e) {
    console.warn('Error: getContenfulDataByKey', e, e.message);
    return {
      error: true
    };
  }
};

export const getPagePropsByContentTypeAndVersion = async (
  contentTypeId,
  version
) => {
  try {
    // Get Contentful Client
    const client = getContentfulClient();

    // Get Page entry from Contentful
    const pagePropsRes = await client.getEntries({
      content_type: contentTypeId,
      'fields.name': version
    });

    // Get the props field from the entry and save it in a variable
    let pageProps = pagePropsRes?.items?.[0]?.fields?.pageProps;

    if (!pageProps) {
      throw new Error('Invalid response from contentful ', pagePropsRes);
    }

    return pageProps;
  } catch (e) {
    console.warn(
      'Error: getPagePropsByContentTypeAndVersion',
      contentTypeId,
      version,
      e,
      e.message
    );
    return {
      error: true
    };
  }
};

export const getIndiaHomePageCmsProps = async () => {
  const version = 'v1';
  return await getPagePropsByContentTypeAndVersion(
    CONTENTFUL_NASIO_INDIA_HOME_PAGE_CONTENT_TYPE_ID,
    version
  );
};

export const getNewHomePageCMSProps = async () => {
  try {
    // Get Contentful Client
    const client = getContentfulClient();

    // Get home Page entry from Contentful
    const pagePropsRes = await client.getEntries({
      content_type: CONTENTFUL_NASIO_HOME_PAGE_CONTENT_TYPE_ID,
      'fields.name': 'nasio-homepage-v7'
    });

    // Get the props field from the entry and save it in a variable
    let pageProps = pagePropsRes?.items?.[0]?.fields?.pageProps;

    if (!pageProps) {
      throw new Error('Invalid response from contentful ', pagePropsRes);
    }

    return pageProps;
  } catch (e) {
    console.warn('Error: getHomePageProps', e, e.message);
    return {
      error: true
    };
  }
};

export const getAboutUsPageCMSProps = async () => {
  try {
    // Get Contentful Client
    const client = getContentfulClient();

    // Get home Page entry from Contentful
    const pagePropsRes = await client.getEntries({
      content_type: CONTENTFUL_NASIO_ABOUT_US_PAGE_CONTENT_TYPE_ID,
      'fields.name': 'about-us-page-v1'
    });

    // Get the props field from the entry and save it in a variable
    let pageProps = pagePropsRes?.items?.[0]?.fields?.pageProps;

    if (!pageProps) {
      throw new Error('Invalid response from contentful ', pagePropsRes);
    }

    return pageProps;
  } catch (e) {
    console.warn('Error: getHomePageProps', e, e.message);
    return {
      error: true
    };
  }
};

export const getPricingPageCMSProps = async () => {
  try {
    // Get Contentful Client
    const client = getContentfulClient();

    // Get Pricing Page entry from Contentful
    const pagePropsRes = await client.getEntries({
      content_type: CONTENTFUL_PRICING_PAGE_CONTENT_TYPE_ID,
      'fields.name': 'nas-io-pricing-page-v2'
    });

    // Get the props field from the entry and save it in a variable
    let pageProps = pagePropsRes?.items?.[0]?.fields?.pageProps;

    if (!pageProps) {
      throw new Error('Invalid response from contentful ', pagePropsRes);
    }

    return pageProps;
  } catch (e) {
    console.warn('Error: getPricingPageProps', e, e.message);
    return {
      error: true
    };
  }
};

export const getStaticCreatorCourseCardsForCourseCodes = async (
  courseCodes
) => {
  try {
    // Get Contentful Client
    const client = getContentfulClient();
    // Need the homepage data to get the creator cards
    const homepageData = await client.getEntries({
      content_type: CONTENTFUL_HOMEPAGE_CONTENT_TYPE_ID
    });

    const creatorCardData = homepageData?.items?.reduce((acc, item) => {
      const { creatorCards } = item.fields;
      const cardsToKeep = Object.values(creatorCards).filter((card) =>
        courseCodes.some((c) => c === card.courseCode)
      );
      cardsToKeep.forEach((card) => {
        acc[card.courseCode] = card;
      });
      return acc;
    }, {});
    return creatorCardData;
  } catch (e) {
    console.warn(
      'Error: getCreatorCourseCardsForCourseCodes',
      e,
      e.message
    );
    return {
      error: true
    };
  }
};

export const getWhatsappPageCMSProps = async () => {
  try {
    // Get Contentful Client
    const client = getContentfulClient();

    // Get Pricing Page entry from Contentful
    const pagePropsRes = await client.getEntries({
      content_type: CONTENTFUL_NASIO_HOME_PAGE_CONTENT_TYPE_ID,
      'fields.name': 'whatsapp-page-v2'
    });

    // Get the props field from the entry and save it in a variable
    let pageProps = pagePropsRes?.items?.[0]?.fields?.pageProps;

    if (!pageProps) {
      throw new Error('Invalid response from contentful ', pagePropsRes);
    }

    return pageProps;
  } catch (e) {
    console.warn('Error: getPricingPageProps', e, e.message);
    return {
      error: true
    };
  }
};
