// AVAILABLE SECTIONS KEYS
export const OG_META_TAGS = 'OG_META_TAGS';
export const VIDEO_BANNER = 'VIDEO_BANNER';
export const LIVE_OVERVIEW = 'LIVE_OVERVIEW'; // TODO: Need to rename to COHORT_OVERVIEW
export const MSG_FROM_CREATOR = 'MSG_FROM_CREATOR';
export const TESTIMONIALS = 'TESTIMONIALS';
export const TESTIMONIALS_2 = 'TESTIMONIALS_2';
export const WHAT_YOU_WILL_CREATE = 'WHAT_YOU_WILL_CREATE';
export const WHAT_YOU_WILL_CREATE_2 = 'WHAT_YOU_WILL_CREATE_2';
export const WHAT_YOU_WILL_CREATE_3 = 'WHAT_YOU_WILL_CREATE_3';
export const WHAT_YOU_WILL_LEARN = 'WHAT_YOU_WILL_LEARN';
export const WHAT_I_WILL_TECH = 'WHAT_I_WILL_TECH';
export const STUDENT_ALSO_VIEWED = 'STUDENT_ALSO_VIEWED';
export const SCHEDULE = 'SCHEDULE';
export const SCHEDULE_V2 = 'SCHEDULE_V2';
export const COHORT_EXPLAIN = 'COHORT_EXPLAIN';
export const COURSE_EXPERIENCE = 'COURSE_EXPERIENCE';
export const CTA_DARK_BG_SECTION = 'CTA_DARK_BG_SECTION';
export const ABOUT_ME = 'ABOUT_ME';
export const ABOUT_ME_2 = 'ABOUT_ME_2';
export const MESSAGE_FROM_ME_COLLAPSIBLE = 'MESSAGE_FROM_ME_COLLAPSIBLE';
export const USER_FEEDBACKS = 'USER_FEEDBACKS';
export const FAQ = 'FAQ';
export const CTA_WITH_ASSET_BG = 'CTA_WITH_ASSET_BG';
export const STUDENT_WORKS_CAROUSEL = 'STUDENT_WORKS_CAROUSEL';
export const TOP_NAV_LINKS = 'TOP_NAV_LINKS';
export const BOTTOM_NAV_LINKS = 'BOTTOM_NAV_LINKS';
export const RESOURCE_GRID_SECTION = 'RESOURCE_GRID_SECTION';
export const APPLY_FOR_SCHOLARSHIP_SECTION =
  'APPLY_FOR_SCHOLARSHIP_SECTION';
export const SPONSORS_BANNER_SECTION = 'SPONSORS_BANNER_SECTION';
export const SPONSORS_BANNER_SECTION_2 = 'SPONSORS_BANNER_SECTION_2';
export const REMIND_ME_SECTION = 'REMIND_ME_SECTION';
export const QUOTE = 'QUOTE';
export const COMMUNITY_INFO_CARD_SECTION = 'COMMUNITY_INFO_CARD_SECTION';
export const PRICING_SECTION = 'PRICING_SECTION';
export const WHAT_YOU_WILL_GET_SECTION = 'WHAT_YOU_WILL_GET_SECTION';
export const BANNER_CAROUSEL_SECTION = 'BANNER_CAROUSEL_SECTION';
export const LIVE_OVERVIEW_WAVE_START_DATE_TYPE = 'waveStartDate'; // Rename to COHORT_OVERVIEW_WAVE_START_DATE_TYPE
export const COHORT_COURSE_TYPES = {
  COHORT_COURSE: 'COHORT_COURSE',
  FELLOWSHIP: 'FELLOWSHIP'
};

export const cmsToSectionKeyMap = {
  ogMetaData: OG_META_TAGS,
  bannerSectionProps: VIDEO_BANNER,
  overviewSectionProps: LIVE_OVERVIEW,
  messageFromCreatorSectionProps: MSG_FROM_CREATOR,
  testimonialsSectionProps: TESTIMONIALS,
  testimonialsSectionProps_2: TESTIMONIALS_2,
  whatYouWillCreateSectionProps: WHAT_YOU_WILL_CREATE,
  whatYouWillCreateSectionProps_2: WHAT_YOU_WILL_CREATE_2,
  whatYouWillCreateSectionProps_3: WHAT_YOU_WILL_CREATE_3,
  whatYouWillLearnSectionProps: WHAT_YOU_WILL_LEARN,
  whatIWillTeachSectionProps: WHAT_I_WILL_TECH,
  studentWorkSectionProps: STUDENT_WORKS_CAROUSEL,
  sponsorsBannerSectionProps: SPONSORS_BANNER_SECTION,
  sponsorsBannerSectionProps_2: SPONSORS_BANNER_SECTION_2,
  courseScheduleSectionProps: SCHEDULE,
  studentsAlsoViewed: STUDENT_ALSO_VIEWED,
  courseScheduleSectionV2Props: SCHEDULE_V2,
  cohortExplanationSectionProps: COHORT_EXPLAIN,
  courseExperienceProps: COURSE_EXPERIENCE,
  ctaWithDarkBgSectionProps: CTA_DARK_BG_SECTION,
  userFeedbackSection: USER_FEEDBACKS,
  aboutMeSectionProps: ABOUT_ME,
  aboutMeSectionProps_2: ABOUT_ME_2,
  messageFromMeCollapsibleProps: MESSAGE_FROM_ME_COLLAPSIBLE,
  faqSectionProps: FAQ,
  ctaWithAssetBgSectionProps: CTA_WITH_ASSET_BG,
  topStickyNavSectionProps: TOP_NAV_LINKS,
  bottomStickySectionProps: BOTTOM_NAV_LINKS,
  resourceGridSectionProps: RESOURCE_GRID_SECTION,
  applyForScholarshipSectionProps: APPLY_FOR_SCHOLARSHIP_SECTION,
  remindMeSectionProps: REMIND_ME_SECTION,
  quoteSectionProps: QUOTE,
  communityInfoCardSectionProps: COMMUNITY_INFO_CARD_SECTION,
  pricingSectionProps: PRICING_SECTION,
  bannerCarouselSectionProps: BANNER_CAROUSEL_SECTION,
  whatYouWillGetSectionProps: WHAT_YOU_WILL_GET_SECTION
};
