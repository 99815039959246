import {
  DB_COMMUNITY_CONTENT_TYPE_ID,
  DB_EVENT_PUBLIC_PAGE_CONTENT_TYPE_ID,
  DB_RESOURCE_PUBLIC_PAGE_CONTENT_TYPE_ID,
  getAllCommunityPageSlugsFromContentful,
  getPageDataBySlugFromContentful
} from '@/utility/contentful/utils';
import {
  getCommunityDataService,
  getEventBySlug,
  getResourceBySlug
} from '@/services/communitiesService';

import LandingPageContent from './LandingPageContent';
import LoadingMainContent from '../features/community/components/LoadingMainContent';
import config from '@/utility/config';
import { useRouter } from 'next/router';

const ContentfulCoursePage = (props) => {
  const router = useRouter();
  if (router.isFallback) {
    return <LoadingMainContent />;
  }
  return <LandingPageContent {...props} />;
};

// Read More: https://nextjs.org/learn/basics/dynamic-routes/page-path-external-data
export async function getStaticPaths() {
  const slugs = await getAllCommunityPageSlugsFromContentful();

  const paths = slugs.map((slug) => {
    // remove '/' prefix from slug if it exists.
    const trimmedSlug = slug.trim();
    const cmsSlug =
      trimmedSlug.charAt(0) === '/' ? trimmedSlug.substr(1) : trimmedSlug;

    // Returning array instead of string for cmsSlug to support creating nested dynamic routes.
    // ex. /dearalyne/money will return ['dearalyne', 'money']
    // ex. /nuseir will return ['nuseir']
    return {
      params: {
        cmsSlug: cmsSlug.split('/')
      }
    };
  });

  return {
    paths,
    fallback: true
  };
}

export async function getStaticProps(context) {
  // Convert slug from array to string & add prefix '/'
  const slug = '/' + context.params.cmsSlug.join('/');

  const start = Date.now();

  let errorFetchingPage = false;
  let pageExists = true;
  let pageInfo = null;

  const res = await getCommunityDataService(slug);

  const { data, error } = res;

  pageInfo = {
    templateData: data,
    templateContentType: DB_COMMUNITY_CONTENT_TYPE_ID
  };

  const shortCodeSlug =
    context.params.cmsSlug[context.params.cmsSlug.length - 1];

  const communitySlug =
    context.params.cmsSlug[context.params.cmsSlug.length - 2];

  const { data: eventData, error: eventError } = await getEventBySlug(
    `${communitySlug}/${shortCodeSlug}`
  );

  if (eventData && !eventError) {
    pageInfo = {
      templateData: {
        ...eventData,
        communitySlug,
        eventSlug: shortCodeSlug
      },
      templateContentType: DB_EVENT_PUBLIC_PAGE_CONTENT_TYPE_ID
    };
    return {
      props: { pageInfo, pageExists, errorFetchingPage },
      revalidate: config.contentfulRevalidateSec
    };
  }

  const { data: resourceData, error: resourceError } =
    await getResourceBySlug(`${communitySlug}/${shortCodeSlug}`);

  //If the community is inactive, the resource will be not available
  if (
    resourceData &&
    resourceData.communityInfo &&
    !resourceData.communityInfo.isActive
  ) {
    return {
      props: { pageInfo: {}, errorFetchingPage: true, pageExists: false },
      revalidate: config.contentfulRevalidateSec
    };
  }

  if (resourceData && !resourceError) {
    pageInfo = {
      templateData: {
        ...resourceData,
        communitySlug,
        resourceSlug: shortCodeSlug
      },
      templateContentType: DB_RESOURCE_PUBLIC_PAGE_CONTENT_TYPE_ID
    };
    return {
      props: { pageInfo, pageExists, errorFetchingPage },
      revalidate: config.contentfulRevalidateSec
    };
  }

  if (error) {
    // 2. Check CMS
    pageInfo = await getPageDataBySlugFromContentful(slug);
    // Currently, cant tell if page doesn't exist from CMS so will just set not exist if request fails
    if (!pageInfo?.templateData) {
      errorFetchingPage = true;
      pageExists = false;
    }
  }

  const end = Date.now();
  const execTimeSec = (end - start) / 1000;
  console.log(
    `\nExecution time to get props from await getPageDataBySlugFromContentful(${slug}) : ${execTimeSec} seconds`
  );

  return {
    props: { pageInfo, errorFetchingPage, pageExists },
    revalidate: config.contentfulRevalidateSec
  };
}

export default ContentfulCoursePage;
