import { STUDENT_ALSO_VIEWED } from '../CohortCoursePage/constants';

export const capCourseCode = 'CREATOR_ACCELERATOR_PROGRAM';

export const PAGE_STATUS = {
  CAP_APPLICATION_LIVE: 'CAP Application Live',
  COMMUNITY_PAID_CHECKOUT: 'Community Paid Checkout',
  WAITLIST_CAP: 'Waitlist CAP',
  TWO_BUTTON_LAYOUT: 'Two Button Layout'
};

export const starImageData = {
  alt: 'Star Rating',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/CreatorCourseDark/svg/stars.svg',
    width: 125,
    height: 32
  },
  desktopImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/CreatorCourseDark/svg/stars.svg',
    width: 125,
    height: 32
  }
};

export const heroSectionContainerImage =
  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/Home_page_new/HeroSection-Container.svg';
export const programOverViewCreatorLeft =
  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/Home_page_new/image-left-creator.png';
export const programOverViewCreatorTopRight =
  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/Home_page_new/image-top-right-creator.png';
export const programOverViewCreatorBottomRight =
  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/Home_page_new/image-bottom-right-creator+(2).png';
export const appsFlyerLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/appsflyer-Logo.svg';
export const metapurseLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/metapurse-Logo.svg';
export const dwLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/dw-Logo.svg';
export const bayerLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/Bayer-Logo.svg';
export const pfizerLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/Pfizer-Logo.svg';
export const epidemicSoundLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/epidemic_sound-Logo.svg';
export const grabLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/grab-Logo.svg';
export const canonLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/canon-Logo.svg';
export const singaporeAirlinesLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/Singapore_airlines-Logo.svg';
export const salesForceLoginLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/salesforce-Logo.svg';
export const netflixLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/Netflix-Logo.svg';
export const facebookLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/Facebook-Logo.svg';
export const metaPlatformsLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/Meta_Platforms-Logo.svg';
export const titTokLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/Tiktok-Logo.svg';
export const googleLogo =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/Home_page_new/Google-Logo.svg';

export const BRANDS_WE_WORK_WITH_LOGOS = [
  googleLogo,
  titTokLogo,
  metaPlatformsLogo,
  facebookLogo,
  netflixLogo,
  singaporeAirlinesLogo,
  salesForceLoginLogo,
  canonLogo,
  grabLogo,
  epidemicSoundLogo,
  pfizerLogo,
  bayerLogo,
  dwLogo,
  metapurseLogo,
  appsFlyerLogo
];
export const BRANDS_SECTION_PROPS = {
  allBrands: BRANDS_WE_WORK_WITH_LOGOS
};

// CAP Landing Page Specific Sections
export const OG_SECTION = 'OG_SECTION';
export const LEARN_HOW_TO_SECTION = 'LEARN_HOW_TO_SECTION';
export const GOALS_SECTION = 'GOALS_SECTION';
export const QUOTE_SECTION_1 = 'QUOTE_SECTION_1';
export const STRUGGLING_SECTION = 'STRUGGLING_SECTION';
export const HELP_SECTION = 'HELP_SECTION';
export const CAREER_GUIDANCE_SECTION = 'CAREER_GUIDANCE_SECTION';
export const SUCCESS_SECTION = 'SUCCESS_SECTION';
export const STUDENT_WORK_SECTION = 'STUDENT_WORK_SECTION';
export const BRANDS_SECTION = 'BRANDS_SECTION';
export const QUOTE_SECTION_3 = 'QUOTE_SECTION_3';
export const LEARN_FROM_EXPERT_TRAINERS_SECTION =
  'LEARN_FROM_EXPERT_TRAINERS_SECTION';
export const FEATURED_GUEST_SPEAKERS_SECTION =
  'FEATURED_GUEST_SPEAKERS_SECTION';
export const JOURNEY_SECTION = 'JOURNEY_SECTION';
export const QUOTE_SECTION_2 = 'QUOTE_SECTION_2';
export const IMMERSIVE_LEARNING_SECTION = 'IMMERSIVE_LEARNING_SECTION';
export const ASK_YOUR_COMPANY_SECTION = 'ASK_YOUR_COMPANY_SECTION';
export const READY_TO_ACCELERATE_YOUR_GROWTH_SECTION =
  'READY_TO_ACCELERATE_YOUR_GROWTH_SECTION';
export const WE_LOVE_GETTING_FEEDBACK_SECTION =
  'WE_LOVE_GETTING_FEEDBACK_SECTION';
export const FAQ_SECTION = 'FAQ_SECTION';
export const FUTURE_JOBS_SECTION = 'FUTURE_JOBS_SECTION';

// Old Sections
export const BANNER_SECTION = 'BANNER_SECTION';
export const EXPLORE_CATEGORIES = 'EXPLORE_CATEGORIES';
export const ROTATING_TITLE_SECTION = 'ROTATING_TITLE_SECTION';
export const CREATOR_SPOTLIGHT = 'CREATOR_SPOTLIGHT';
export const CLASSMATES_SECTION = 'CLASSMATES_SECTION';
export const LEARNING_STATS_SECTION = 'LEARNING_STATS_SECTION';
export const STUDENT_WORK_CARDS_SECTION = 'STUDENT_WORK_CARDS_SECTION';
export const CLASS_PICKER_SECTION = 'CLASS_PICKER_SECTION';
export const STUDENT_WORKS_CAROUSEL_SECTION =
  'STUDENT_WORKS_CAROUSEL_SECTION';
export const BUNDLES_CARDS_SECTION = 'BUNDLES_CARDS_SECTION';
export const DEGREES_CARDS_SECTION = 'DEGREES_CARDS_SECTION';
export const STUDENT_SUCCESS_STORIES_SECTION =
  'STUDENT_SUCCESS_STORIES_SECTION';
export const CREATORS_CTA_SECTION = 'CREATORS_CTA_SECTION';
export const PARTNERS_SECTION = 'PARTNERS_SECTION';
export const DYNAMIC_CONTENT_CREATION_SECTION =
  'DYNAMIC_CONTENT_CREATION_SECTION';
export const FREE_WORKSHOP_SECTION = 'FREE_WORKSHOP_SECTION';
export const LEARN_BY_DOING_SECTION = 'LEARN_BY_DOING_SECTION';

export const OG_META_DATA = 'OG_META_DATA';
export const STUDENT_WORK_MODAL_DATA = 'STUDENT_WORK_MODAL_DATA';
export const CLASS_EXPERIENCE_SECTION = 'CLASS_EXPERIENCE_SECTION';
export const COMMUNITY_SECTION = 'COMMUNITY_SECTION';

export const DEFAULT_SECTIONS_ORDER = [
  OG_META_DATA,
  ROTATING_TITLE_SECTION,
  BANNER_SECTION,
  EXPLORE_CATEGORIES,
  CREATOR_SPOTLIGHT,
  CLASS_EXPERIENCE_SECTION,
  CLASSMATES_SECTION,
  LEARNING_STATS_SECTION,
  DEGREES_CARDS_SECTION,
  FREE_WORKSHOP_SECTION,
  STUDENT_WORK_CARDS_SECTION,
  STUDENT_SUCCESS_STORIES_SECTION,
  CREATORS_CTA_SECTION,
  PARTNERS_SECTION,
  DYNAMIC_CONTENT_CREATION_SECTION
];

export const sectionIdToHompageComponentMap = {
  ogMetaData: OG_SECTION,
  rotatingTitleSection: ROTATING_TITLE_SECTION,
  classPickerSection: CLASS_PICKER_SECTION,
  communitySection: COMMUNITY_SECTION,
  studentsAlsoViewed: STUDENT_ALSO_VIEWED,
  studentWorksCarouselSection: STUDENT_WORKS_CAROUSEL_SECTION,
  learnHowToSection: LEARN_HOW_TO_SECTION,
  goalsSection: GOALS_SECTION,
  quoteSection: QUOTE_SECTION_1,
  quoteSection1: QUOTE_SECTION_1,
  quoteSection2: QUOTE_SECTION_2,
  quoteSection3: QUOTE_SECTION_3,
  strugglingSection: STRUGGLING_SECTION,
  helpSection: HELP_SECTION,
  careerGuidanceSection: CAREER_GUIDANCE_SECTION,
  successSection: SUCCESS_SECTION,
  studentWorkSection: STUDENT_WORK_SECTION,
  brandsSection: BRANDS_SECTION,
  learnFromExpertTrainersSection: LEARN_FROM_EXPERT_TRAINERS_SECTION,
  featuredGuestSpeakersSection: FEATURED_GUEST_SPEAKERS_SECTION,
  journeySection: JOURNEY_SECTION,
  immersiveLearningSection: IMMERSIVE_LEARNING_SECTION,
  askYourCompanySection: ASK_YOUR_COMPANY_SECTION,
  readyToAccelerateYourGrowthSection:
    READY_TO_ACCELERATE_YOUR_GROWTH_SECTION,
  weLoveGettingFeedbackSection: WE_LOVE_GETTING_FEEDBACK_SECTION,
  faqSection: FAQ_SECTION,
  futureJobsSection: FUTURE_JOBS_SECTION
};

export const newHomePageSections = [
  OG_SECTION,
  LEARN_HOW_TO_SECTION,
  GOALS_SECTION,
  QUOTE_SECTION_1,
  STRUGGLING_SECTION,
  HELP_SECTION,
  CAREER_GUIDANCE_SECTION,
  SUCCESS_SECTION,
  STUDENT_WORK_SECTION,
  BRANDS_SECTION,
  QUOTE_SECTION_3,
  LEARN_FROM_EXPERT_TRAINERS_SECTION,
  FEATURED_GUEST_SPEAKERS_SECTION,
  JOURNEY_SECTION,
  QUOTE_SECTION_2,
  IMMERSIVE_LEARNING_SECTION,
  ASK_YOUR_COMPANY_SECTION,
  READY_TO_ACCELERATE_YOUR_GROWTH_SECTION,
  WE_LOVE_GETTING_FEEDBACK_SECTION,
  FAQ_SECTION
];
export const WE_LOVE_GETTING_FEEDBACK_SECTION_PROPS = {
  eyebrow: 'Student Reviews',
  title: 'Thousands of student reviews, and counting',
  desktopEmbedData: {
    title: 'desktop-flockler-how-it-works',
    id: 'flockler-embed-iframe-17ba4bb08fe08a7d39ab20b6740b2183',
    embedLink:
      'https://plugins.flockler.com/embed/iframe/17b39f10a0c0de4b544bc67884594fe8/17ba4bb08fe08a7d39ab20b6740b2183',
    height: '800'
  },
  mobileEmbedData: {
    title: 'mobile-flockler-how-it-works',
    id: 'flockler-embed-iframe-17b9a774f15003684ea805b7236fa454',
    embedLink:
      'https://plugins.flockler.com/embed/iframe/17b39f10a0c0de4b544bc67884594fe8/17b9a774f15003684ea805b7236fa454',
    height: '440'
  }
};

export const READY_TO_ACCELERATE_YOUR_GROWTH_SECTION_PROPS = {
  title: 'Ready to accelerate your growth to the next level?',
  titleWithDifferentColor: 'We’re ready for you!',
  USP: [
    'Create at least 50 scripts and 30 videos',
    'Get personalized feedback on your work',
    'Master the content creation process',
    'Build an online business or new career',
    'Get career support and networking'
  ],
  applyCtaLink: '/accelerator/apply',
  applyCtaText: 'Join the Waitlist'
};
export const STUDENTS_WORK_PROPS = {
  cards: [
    {
      name: 'Matias A.',
      avatar: {
        alt: 'Matias A.',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/matias.png',
          width: '32',
          height: '32',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/matias.png',
          width: '48',
          height: '48'
        }
      },
      imgData: {
        alt: 'Matias A',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/matias.png',
          width: '312',
          height: '312',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/matias.png',
          width: '560',
          height: '560'
        }
      },
      videoData: {
        alt: 'matias',
        mobileVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 312,
          height: 312,
          srcMap: [
            {
              src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/mp4/matias.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        },
        desktopVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 360,
          height: 480,
          srcMap: [
            {
              src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/mp4/matias.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        }
      },
      modalImgData: {
        alt: 'Matias A',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/matias.png',
          width: '1120',
          height: '1120',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/matias.png',
          width: '1120',
          height: '1120'
        }
      }
    },
    {
      name: 'Joshua B.',
      avatar: {
        alt: 'Joshua B.',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/joshua.png',
          width: '32',
          height: '32',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/joshua.png',
          width: '48',
          height: '48'
        }
      },
      imgData: {
        alt: 'Joshua B.',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/joshua.png',
          width: '312',
          height: '312',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/joshua.png',
          width: '560',
          height: '560'
        }
      },
      videoData: {
        alt: 'joshua',
        mobileVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 312,
          height: 312,
          srcMap: [
            {
              src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/mp4/joshua_b.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        },
        desktopVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 360,
          height: 480,
          srcMap: [
            {
              src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/mp4/joshua_b.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        }
      },
      modalImgData: {
        alt: 'Joshua B.',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/joshua.png',
          width: '1120',
          height: '1120',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/joshua.png',
          width: '1120',
          height: '1120'
        }
      }
    },
    {
      name: 'Britt Johnson',
      avatar: {
        alt: 'Britt Johnson',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/brit.png',
          width: '32',
          height: '32',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/brit.png',
          width: '48',
          height: '48'
        }
      },
      imgData: {
        alt: 'Britt Johnson',
        mobileImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-britt.png',
          width: '312',
          height: '312',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-britt.png',
          width: '560',
          height: '560'
        }
      },
      videoData: {
        alt: 'BJ',
        mobileVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 312,
          height: 312,
          srcMap: [
            {
              src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/mp4/britt_johnson.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        },
        desktopVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 360,
          height: 480,
          srcMap: [
            {
              src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/mp4/britt_johnson.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        }
      },
      modalImgData: {
        alt: 'Britt Johnson',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/britt.png',
          width: '1120',
          height: '1120',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/britt.png',
          width: '1120',
          height: '1120'
        }
      }
    },
    {
      name: 'Marc Williams',
      avatar: {
        alt: 'Marc Williams',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/marc.png',
          width: '32',
          height: '32',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/marc.png',
          width: '48',
          height: '48'
        }
      },
      imgData: {
        alt: 'Marc Williams',
        mobileImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-marc.png',
          width: '312',
          height: '312',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-marc.png',
          width: '560',
          height: '560'
        }
      },
      videoData: {
        alt: 'MV',
        mobileVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 312,
          height: 312,
          srcMap: [
            {
              src: 'https://d1b6ra2xfc58va.cloudfront.net/623efb80-9b57-48cd-a8c7-5214b485c466/mp4/Marc_Williams_DanMace_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        },
        desktopVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 360,
          height: 480,
          srcMap: [
            {
              src: 'https://d1b6ra2xfc58va.cloudfront.net/623efb80-9b57-48cd-a8c7-5214b485c466/mp4/Marc_Williams_DanMace_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        }
      },
      modalImgData: {
        alt: 'Marc Williams',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/marc.png',
          width: '1120',
          height: '1120',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/student_works/thumbnails/marc.png',
          width: '1120',
          height: '1120'
        }
      }
    },
    {
      name: 'Sherry Wong',
      avatar: {
        alt: 'Sherry Wong',
        mobileImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-profile-sherry.png',
          width: '32',
          height: '32',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-profile-sherry.png',
          width: '48',
          height: '48'
        }
      },
      imgData: {
        alt: 'Sherry Wong',
        mobileImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-sherry.png',
          width: '312',
          height: '312',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-sherry.png',
          width: '560',
          height: '560'
        }
      },
      videoData: {
        alt: 'sherry',
        mobileVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 312,
          height: 312,
          srcMap: [
            {
              src: 'https://d1b6ra2xfc58va.cloudfront.net/44e2b381-3b44-444b-8926-a4f341049899/mp4/Sherry_Wong_Ad_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        },
        desktopVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 360,
          height: 480,
          srcMap: [
            {
              src: 'https://d1b6ra2xfc58va.cloudfront.net/44e2b381-3b44-444b-8926-a4f341049899/mp4/Sherry_Wong_Ad_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        }
      },
      modalImgData: {
        alt: 'Sherry Wong',
        mobileImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-sherry.png',
          width: '1120',
          height: '1120',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-sherry.png',
          width: '1120',
          height: '1120'
        }
      }
    },
    {
      name: 'Ben Buchanan',
      avatar: {
        alt: 'Ben Buchanan',
        mobileImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-profile-ben.png',
          width: '32',
          height: '32',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-profile-ben.png',
          width: '48',
          height: '48'
        }
      },
      imgData: {
        alt: 'Ben Buchanan',
        mobileImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-ben.png',
          width: '312',
          height: '312',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-ben.png',
          width: '560',
          height: '560'
        }
      },
      videoData: {
        alt: 'ben',
        mobileVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 312,
          height: 312,
          srcMap: [
            {
              src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/mp4/Ben_DanMace_StudentWorks_video.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        },
        desktopVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 360,
          height: 480,
          srcMap: [
            {
              src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/mp4/Ben_DanMace_StudentWorks_video.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        }
      },
      modalImgData: {
        alt: 'Ben Buchanan',
        mobileImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-ben.png',
          width: '1120',
          height: '1120',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/DanMacePage/png/studentworks-danmace-ben.png',
          width: '1120',
          height: '1120'
        }
      }
    },
    {
      name: 'Vero La Hausse',
      avatar: {
        alt: 'VLH',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/jpg/vero_DanMace_studentworks.png',
          width: '32',
          height: '32',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/jpg/vero_DanMace_studentworks.png',
          width: '48',
          height: '48'
        }
      },
      imgData: {
        alt: 'VLH',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/jpg/vero_DanMace_studentworks.png',
          width: '312',
          height: '312',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/jpg/vero_DanMace_studentworks.png',
          width: '560',
          height: '560'
        }
      },
      videoData: {
        alt: 'Dan Mace',
        mobileVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 312,
          height: 312,
          srcMap: [
            {
              src: 'https://d1b6ra2xfc58va.cloudfront.net/decd5fb3-b025-4471-8130-d594be541b11/mp4/Vero_La_Hausse_DanMace_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        },
        desktopVidProps: {
          loop: true,
          cover: null,
          muted: false,
          width: 360,
          height: 480,
          srcMap: [
            {
              src: 'https://d1b6ra2xfc58va.cloudfront.net/decd5fb3-b025-4471-8130-d594be541b11/mp4/Vero_La_Hausse_DanMace_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4',
              type: 'video/mp4'
            }
          ],
          autoPlay: true,
          playsInline: true,
          checkIsInView: false
        }
      },
      modalImgData: {
        alt: 'vero',
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/jpg/vero_DanMace_studentworks.png',
          width: '1120',
          height: '1120',
          layout: 'responsive'
        },
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/DanMacePage/jpg/vero_DanMace_studentworks.png',
          width: '1120',
          height: '1120'
        }
      }
    }
  ],
  title: 'Student work',
  identifier: 'student-works',
  eyebrowText: 'Nas Academy Alumni',
  hideDotsOnCarousel: true
};

export const GET_CAREER_GUIDANCE_PROPS = {
  imgData: {
    alt: 'Ritesh',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/ssr-academy/ritesh/jpg/ritesh-scholarship-section.jpg',
      width: '400',
      height: '400',
      layout: 'responsive'
    }
  },
  eyebrowText: 'eye Brow test',
  title: 'Get career guidance',
  desc: 'Nec aliquet ac eu, bibendum arcu ut proin tincidunt. Sed pulvinar magna non proin. Porttitor nibh tellus magna eros tincidunt luctus vitae arcu non. Posuere lectus imperdiet ut morbi aliquet.'
};
export const QUOTE_SECTION_1_PROPS = {
  text: 'I am extremely happy with this course.',
  creatorData: {
    name: 'Joshua. F',
    infoText: 'SOUTH AFRICA',
    imgData: {
      alt: 'Joshua. F',
      mobileImgProps: {
        src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/quotes/joshua.png',
        width: '312',
        height: '312',
        layout: 'responsive'
      },
      desktopImgProps: {
        src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/quotes/joshua.png',
        width: '560',
        height: '560'
      },
      className: 'rounded-full'
    }
  },
  author: 'me'
};
export const QUOTE_SECTION_2_PROPS = {
  text: 'Crazy work of art in creating this irresistible course!',
  creatorData: {
    name: 'Ernest H.',
    infoText: 'AUSTRALIA',
    imgData: {
      alt: 'Ernest H.',
      mobileImgProps: {
        src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/quotes/ernest.png',
        width: '312',
        height: '312',
        layout: 'responsive'
      },
      desktopImgProps: {
        src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/quotes/ernest.png',
        width: '560',
        height: '560'
      },
      className: 'rounded-full'
    }
  },
  author: 'me'
};

export const QUOTE_SECTION_3_PROPS = {
  text: "One of the best decisions I've made!",
  creatorData: {
    name: 'Adaline O.',
    infoText: 'CANADA',
    imgData: {
      alt: 'Adaline O.',
      mobileImgProps: {
        src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/quotes/adaline.png',
        width: '312',
        height: '312',
        layout: 'responsive'
      },
      desktopImgProps: {
        src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/quotes/adaline.png',
        width: '560',
        height: '560'
      },
      className: 'rounded-full'
    }
  },
  author: 'me'
};
export const GOALS_SECTION_PROPS = {
  overviewPoints: [
    'Program overview',
    "How we'll help you",
    'Alumni success stories',
    'Meet our trainers',
    'Guest speakers',
    'Student works',
    'Curriculum',
    'Apply',
    'Reviews',
    'FAQ'
  ],
  bookACallCtaLink: '/accelerator/book-a-call',
  applyCtaLink: '/accelerator/apply',
  title: 'A program designed for you to',
  titleDifferentColor: 'start creating',

  description:
    "5 years ago, the world had 5 million social media creators. Today, that number is over 50 million.\n\nWelcome to the Creator Revolution. Now, for the first time ever, you can actually get a job doing what you love.\n\nYou can be a social media manager, a digital marketer on TikTok, or a community manager on Discord. Not only are these the world’s newest jobs, they are guaranteed to be in-demand in future. And they are way more fun than your average 9 to 5, and pay even better! But there hasn't been one single place to learn all these skills. Until now.\n\nWelcome to the Creator Accelerator Program. We designed this program to help you skyrocket your career as a creator. From learning how to grow on social media platforms, to building a network and accessing job opportunities with the world's top brands – this immersive program will transform your career in just 5 months.\n\nThere’s room for everyone in this Revolution, but don’t waste another day.\nJoin today."
};
export const STRUGGLING_SECTION_PROPS = {
  title: 'Are you struggling\nwith these?',
  desc: 'These are some of the most common problems that creators face.',
  strugglingPoints: [
    {
      title: "Your channel just isn't growing fast enough",
      desc: "Perhaps you have already tried many different strategies, but you're still growing slower than others."
    },
    {
      title: 'You are running out of fresh, new ideas',
      desc: 'It becomes increasingly harder to plan what type of content to create and share online.'
    },
    {
      title: 'You are feeling overwhelmed or lonely in the process',
      desc: 'Others are finding success on social media and collaborating with each other – so how can you get involved?'
    },
    {
      title: "People don't care about your product or messaging",
      desc: "You're investing so much into your company’s online presence, but nobody seems to be interested."
    }
  ]
};

export const HOW_WE_HELP_SECTION_PROPS = {
  title: 'Here’s how we’ll help you',
  desc: 'Get access to experts, education, and opportunities in this program.',
  helpingPoints: [
    {
      title: 'Live Online Classes',
      description:
        'Unlike pre-recorded classes, you can get immediate feedback and learn directly from trainers who are actively working in their respective fields.',
      image:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/course_offerings/live_classes.png'
    },
    {
      title: 'Proven Curriculum',
      description:
        'Our students have graduated and become successful with over 500,000 followers in less than a year, using our tried-and-tested formula. ',
      image:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/course_offerings/proven_curriculum.png'
    },
    {
      title: 'Practical Experience',
      description:
        'This program is all about learning by doing. You will start creating from the start and work on at least 30 videos, using only your phone. ',
      image:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/course_offerings/practical_experience.png'
    },
    {
      title: 'Guest Speakers',
      description:
        'Tap into our global network of creators who have established businesses and get exclusive insights into the behind-the-scenes of social media.',
      image:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/course_offerings/guest_speakers.png'
    },
    {
      title: 'Career Support',
      description:
        'We’ll connect you to international brands who are looking to work with creators like yourself and help you perfect your pitch.',
      image:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/course_offerings/career_support.png'
    },
    {
      title: 'Nas Certification',
      description:
        'Founded by Nas Daily (54 million followers), Nas Academy is globally recognized so you’ll stand out by earning our program certificate.',
      image:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/course_offerings/certification.png'
    }
  ],
  bookACallCtaLink: '/accelerator/book-a-call',
  applyCtaLink: '/accelerator/apply'
};
export const LEARN_HOW_TO_SECTION_PROPS = {
  title: 'Spend Your Life Doing Something Fun',
  description:
    '10x your social media reach in 5 months with our flagship Creator Accelerator Program.',
  videoData: {
    src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/mp4/CREATOR_ACCELERATOR_TRAILER.mp4',
    loop: true,
    muted: true,
    autoPlay: true,
    controls: false,
    mobileSrc:
      'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/mp4/CREATOR_ACCELERATOR_TRAILER.mp4',
    playsInline: true
  },
  bookACallCtaLink: '/accelerator/book-a-call',
  applyCtaLink: '/accelerator/apply'
};
export const FUTURE_JOBS_SECTION_PROPS = {
  eyebrow: 'What can you do with content creation skills?  ',
  title: 'Guaranteed jobs of the future...',
  immersiveContent: [
    {
      title: 'Independent Content Creator',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'school'
    },
    {
      title: 'TikTok Influencer',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'tiktok'
    },
    {
      title: 'Discord Community Manager',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'discord'
    },
    {
      title: 'Social Media Specialist',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'social-media-manager'
    },
    {
      title: 'Digital Marketer',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'digital-marketing'
    },
    {
      title: 'Copywriter ',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'copywriter'
    },
    {
      title: 'Content Producer',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'checklist'
    },
    {
      title: 'YouTuber',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'youtube'
    },
    {
      title: 'Scriptwriter',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'typewriter'
    },
    {
      title: 'Video Editor',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'movie-purple'
    },
    {
      title: 'Sound Designer',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'music-green'
    },
    {
      title: 'Visual Effects Artist',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: 'vfx'
    }
  ]
};
export const SUCCESS_STORIES_PROPS = {
  cards: [
    {
      name: 'Uptin',
      country: 'Dubai',
      photo:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/alumni/uptin.png',
      platforms: ['tikTok', 'youtube', 'instagram', 'facebook'],
      audienceGrowth: '1 500 000'
    },
    {
      name: 'DubaiLad',
      country: 'Dubai',
      photo:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/alumni/dubaiLad.png',
      platforms: ['facebook', 'instagram'],
      audienceGrowth: '1 200 00 '
    },
    {
      name: 'Shirley Lina',
      country: 'Philippines',
      photo:
        'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/alumni/shirley.png',
      platforms: ['facebook', 'tikTok', 'youtube'],
      audienceGrowth: '1 000 000 '
    }
  ]
};

export const FAQ_FOR_NEW_PAGE_PROPS = {
  faqs: [
    {
      content: [
        {
          text: 'There are 20 structured sessions over 20 weeks (there will also be additional feedback sessions for those who need a bit more support and guidance).'
        }
      ],
      key: 'How many sessions are there?',
      title: 'How many sessions are there?'
    },
    {
      content: [
        {
          text: 'All classes will be held online. There will be occasional meet-ups, but you do not have to join any of the in-person events to participate in this program.'
        }
      ],
      key: 'Where will the Creator Accelerator Program sessions be held?',
      title: 'Where will the Creator Accelerator Program sessions be held?'
    },
    {
      content: [
        {
          text: 'Online classes happen every Saturday and all sessions are recorded. There will be options available for different time zones depending on where you live.'
        }
      ],
      key: 'What is the schedule for the online live sessions?',
      title: 'What is the schedule for the online live sessions?'
    },
    {
      content: [
        {
          text: 'If you enroll in this program, there is a time committment – including the scheduled sessions. If you are unable to join, there will not be replacement class but you can watch the recording in your own time.'
        }
      ],
      key: 'If I’m busy and have to miss a lesson, can I get a replacement class?',
      title:
        'If I’m busy and have to miss a lesson, can I get a replacement class?'
    },
    {
      content: [
        {
          text: 'If you committ to following the 5-month learning journey, you will:',
          isNewParagraph: true
        },
        {
          isNewParagraph: true
        },
        {
          text: 'Write 50 scripts',
          isNewLine: true
        },
        {
          text: 'Create 30 videos',
          isNewLine: true
        },
        {
          text: 'Create a publishing plan for the next 2 months',
          isNewLine: true
        },
        {
          text: 'Create a portfolio to pitch to brands',
          isNewLine: true
        },
        {
          text: 'Meet (at least) 5 professional creators',
          isNewLine: true
        },
        { text: 'Connect with top brands', isNewLine: true },
        { text: 'Get access to job opportunities' }
      ],
      key: 'What exactly would I achieve if I enroll in the Creator Accelerator Program?',
      title:
        'What exactly would I achieve if I enroll in the Creator Accelerator Program?'
    },
    {
      content: [
        {
          text: 'Places are limited and capped at 50 students per cohort. The program is designed to be as immersive as possible and provide you with personalized guidance.'
        }
      ],
      key: 'What is the size of each class?',
      title: 'What is the size of each class?'
    },
    {
      content: [
        {
          text: 'No! You don’t need any equipment – all you need is your phone!'
        }
      ],
      key: 'Do I need to buy any expensive equipment before I can start the class?',
      title:
        'Do I need to buy any expensive equipment before I can start the class?'
    },
    {
      content: [
        {
          text: 'Yes, the Creator Accelerator Program is designed to take people with any level of experience and turn them into content creators! '
        }
      ],
      key: 'I have no experience with content creation, is this the right program for me?',
      title:
        'I have no experience with content creation, is this the right program for me?'
    },
    {
      content: [
        {
          text: 'Yes, the Creator Accelerator Program is designed to take people with any level of experience and help them grow as content creators!'
        }
      ],
      key: 'I already have some experience with content creation, is this the right program for me?',
      title:
        'I already have some experience with content creation, is this the right program for me?'
    },
    {
      content: [
        {
          text: 'In today’s world, it’s normal for companies, business leaders, and entrepreneurs to build their online presence through social media content creation. We have worked with more than 50 companies to show them how to build and grow their social media channels. The Creator Accelerator Program is a perfect fit for industry professionals who understand the importance of social media for brand awareness and marketing – and want to learn how to successfully do it in an accelerated way.'
        }
      ],
      key: 'If I am an entrepreneur or work for a company, is this program for me?',
      title:
        'If I am an entrepreneur or work for a company, is this program for me?'
    },
    {
      content: [
        {
          text: 'At Nas Academy, we have trained thousands of creators and we’ve learned that it takes 5 months of commitment to learn, practice, and grow on social media. Other programs teach you the skills you need to create content, but then leave you on your own to figure it out.',
          isNewParagraph: true
        },
        {
          text: 'That’s why we have created the most comprehensive and in-depth creator program out there, to better support you each step of the way and get results while learning.'
        }
      ],
      key: 'Why does this program take 20 weeks to complete? ',
      title: 'Why does this program take 20 weeks to complete? '
    },
    {
      content: [
        {
          text: 'The Creator Accelerator Program is $600 per month ($3,000 USD for 5 months) – and you have the option to pay in installments.'
        }
      ],
      key: 'How much does this program cost?',
      title: 'How much does this program cost?'
    },
    {
      content: [
        {
          text: 'This program is packed with value and is worth so much more than the quoted price! Besides the skills you’ll learn, you’ll also get access to job opportunities and networks that will more than pay back the cost of the program. This program is an investment in your future, and is proof to prospective employers that you are committed to your content creation career.'
        }
      ],
      key: 'Why is this program so expensive?',
      title: 'Why is this program so expensive?'
    },
    {
      content: [
        {
          text: 'Yes, you can request a call with one of our Student Advisors and they will share more about the program – and also answer your questions.'
        }
      ],
      key: 'I am not ready to buy yet, is there a way I can find out more information?',
      title:
        'I am not ready to buy yet, is there a way I can find out more information?'
    },
    {
      content: [
        {
          text: 'If your goal is to reach out to a wider market and sustain engagement with your online audience, the Creator Accelerator Program will teach you how to do that. If you’re doing any kind of marketing these days, you have to know how to make your brand stand out on social media and connect with consumers.'
        }
      ],
      key: "I'm a business owner. How would this program be relevant for me?",
      title:
        "I'm a business owner. How would this program be relevant for me?"
    },
    {
      content: [
        {
          text: 'Yes! The Creator Accelerator Program is designed to take people where they are and help them get to where they want to be, even if they have a busy schedule. You can take this program if you want to be a part-time creator or do it as a hobby.'
        }
      ],
      key: 'I am not a full-time creator but I want to create content on the side, is this the right program for me?',
      title:
        'I am not a full-time creator but I want to create content on the side, is this the right program for me?'
    }
  ],
  selectedOpenFaq: 0,
  showModalFaqsCta: false,
  customTitle: 'Got questions?\nWe have answers!',
  withoutTitle: true
};
export const JOURNEY_SECTION_PROPS = {
  bookACallCtaLink: '/accelerator/book-a-call',
  applyCtaLink: '/accelerator/apply',
  items: [
    {
      type: 'general',
      title: 'Master the Art of Social Media Storytelling ',
      points: [
        {
          icon: 'check',
          text: 'Learn the basic writing principles, recommended structure, and various styles of storytelling that will help you get started on your first script'
        },
        {
          icon: 'check',
          text: 'Understand the golden rules behind stories that people find interesting'
        },
        {
          icon: 'check',
          text: 'Discover how to use and play with words to instantly hook your audience'
        },
        {
          icon: 'check',
          text: 'Identify your niche and find an audience so that you can better plan the content ideas and creative style behind your channel'
        },
        {
          icon: 'check',
          text: 'Study our tried-and-tested processes that will help you maximize productivity, effectiveness, and efficiency across all of your writing work'
        }
      ],
      description:
        'If you want a viral video that will reach thousands of people, you have to start by perfecting the script first. No matter what type of content you create or what message you want to share, storytelling is a fundamental skill to learn and practice – which is why you will write at least 50 scripts by the end of this program.',
      titlePrefix: '01',
      isOpenByDefault: true
    },
    {
      type: 'general',
      title: 'The Basics of Content Creation for All Social Platforms',
      points: [
        {
          icon: 'check',
          text: 'Experiment with lighting and discover its impact on the viewing experience for your audience'
        },
        {
          icon: 'check',
          text: 'Learn how to use shotlists and storyboards to make it as easy as possible to get the shot, without reshooting multiple times'
        },
        {
          icon: 'check',
          text: 'Get comfortable with all the features you need when editing your video so that you can incorporate cuts, transitions, and music to tell an engaging story'
        },
        {
          icon: 'check',
          text: 'Save time and avoid the most common mistakes the creators make when editing their videos for various social media platforms'
        }
      ],
      description:
        'Every social media platform has a different algorithm, and you’ll learn about that, but you also have to understand the basic techniques that’ll be the same across all your videos. By first developing a solid content creation process that works for you, you’ll be able to develop a unique style and keep your audience coming back for more.',
      titlePrefix: '02',
      isOpenByDefault: false
    },
    {
      type: 'general',
      title: 'Create Better Videos and Get More Views',
      points: [
        {
          icon: 'check',
          text: 'Discover how to use trends to your advantage and spot an opportunity to replicate techniques used in other videos'
        },
        {
          icon: 'check',
          text: 'Deep dive into the world of sound design and learn how to manipulate different audio effects to create an emotionally compelling story'
        },
        {
          icon: 'check',
          text: 'Take the production quality of your videos to the next level through set design and advanced editing techniques'
        },
        {
          icon: 'check',
          text: 'Create basic visual effects and animations that will get people to stop scrolling and watch your video'
        }
      ],
      description:
        'The best way to get better at creating videos is through practice, which is why you will create at least 30 videos by the end of this program. Once you’ve mastered the basics, you’ll move on to more advanced techniques that are going to help you keep your content fresh and stand out online.',
      titlePrefix: '03',
      isOpenByDefault: false
    },
    {
      type: 'general',
      title: 'Grow Your Channels and Get More Loyal Followers',
      points: [
        {
          icon: 'check',
          text: 'Get the latest insights and algorithm updates for all the major platforms like Instagram, Facebook, Linkedin, YouTube, Snapchat, and TikTok'
        },
        {
          icon: 'check',
          text: 'Search Engine Optimization (SEO) is the key to being discovered organically through search. Learn how to optimize your content with titles and hashtags'
        },
        {
          icon: 'check',
          text: 'A look behind the scenes on how to find brand deals, approach brands, and build your network'
        },
        {
          icon: 'check',
          text: 'Guidance on how to start building a freelance or full-time team to support you '
        }
      ],
      description:
        'After all the effort to craft your content, now you want to share it with the world – and make sure that they see it! The goal is to get as many eyes on your videos as possible every time you post, and we will share exclusive insider tips and tricks that you can use to make it happen.',
      titlePrefix: '04',
      isOpenByDefault: false
    },
    {
      type: 'general',
      title: 'Make Money With Content Creation and Get Job Opportunities ',
      points: [
        {
          icon: 'check',
          text: 'Explore all of the job opportunities that’ll be available to you as a student in this program and create your portfolio according to your goals'
        },
        {
          icon: 'check',
          text: 'Connect with other professionals and find ways to collaborate with each other'
        },
        {
          icon: 'check',
          text: 'Create a business plan and get feedback from industry experts who already have experience in what you’re interested in doing'
        },
        {
          icon: 'check',
          text: 'Meet brand representatives for job opportinities with international companies'
        },
        {
          icon: 'check',
          text: 'Get the opportunity to join and potentially win one of our renowned Nas hackathons'
        }
      ],
      description:
        'Whether you’re working for a company or starting your own business, there are countless opportunities for content creators – and the career options are increasing every day. We will connect you with some of the world’s biggest creators and brands so that you can get the chance to pitch to them, and we’ll even advise you on how to perfect your portfolio.',
      titlePrefix: '05',
      isOpenByDefault: false
    }
  ],
  title: 'Your 5-month learning journey',
  journeyInformation: [
    {
      title: '5 months',
      icons: 'clock'
    },
    {
      title: '1 live sessions per week',
      icons: 'video-camera'
    },
    {
      title: '4h per week commitment',
      icons: 'hourglass_top'
    }
  ],
  rightCTAPoints: [
    {
      text: 'Create at least 50 scripts and 30 videos',
      icon: 'check_icon'
    },
    {
      text: 'Get personalized feedback on your work',
      icon: 'check_icon'
    },
    {
      text: 'Master the end-to-end process of content creation',
      icon: 'check_icon'
    },
    {
      text: 'Build an online business or new career from scratch',
      icon: 'check_icon'
    },
    {
      text: 'Get career support from our network of professionals',
      icon: 'check_icon'
    }
  ],
  cohortStartDate: 'Jan 2023'
};

export const LEARN_FROM_EXPERT_TRAINERS_PROPS = {
  title: 'Learn from our expert trainers',
  subtitle:
    'Our lineup of program trainers are all current social media content creators.',
  cardData: [
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/bea.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/bea.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Bea Chu',
      description: 'Videography',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/belik.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/belik.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Borderless Belik',
      description: 'Videography',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/cassandra.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/cassandra.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Cassandra Aragon',
      description: 'Branding',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/franziska.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/franziska.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Franziska Niehus',
      description: 'Scriptwriting',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/shaganaa.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/shaganaa.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Shaganaa Luvendran',
      description: 'Scriptwriting',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/miah.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/miah.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Miah Su',
      description: 'Branding',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/kavya.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/kavya.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Kavya Saxena',
      description: 'Business',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/kayla.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/kayla.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Kayla Smith',
      description: 'Business',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/nicole.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/nicole.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Nicole Bannister',
      description: 'Scriptwriting',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/nikki.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/nikki.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Nikki Mier',
      description: 'Videography',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/psy.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/psy.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Psy Sethu',
      description: 'Scriptwriting',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/ross.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/ross.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Ross OC Jennings',
      description: 'Videography',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/sara.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/sara.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Sara Novoselac',
      description: 'Business',
      isFilled: true
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/stefanos.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/expert_trainers/stefanos.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      title: 'Stefanos Antypas',
      description: 'Videography',
      isFilled: true
    }
  ]
};

export const FEATURED_GUEST_SPEAKERS_PROPS = {
  title: 'Our past guest speakers ',
  subtitle:
    'We invite some of the top creators in different business niches to share insider tips and tricks.',
  cardData: [
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/nuseir.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/nuseir.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      isFilled: false
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/alyne.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/alyne.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      isFilled: false
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/markian.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/markian.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      isFilled: false
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/ritesh.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/ritesh.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      isFilled: false
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/ng_ming.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/ng_ming.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      isFilled: false
    },
    {
      imageProps: {
        desktopImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/tony.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        },
        mobileImgProps: {
          src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/NAS_ACADEMY_CREATOR_ACCELERATOR_PROGRAM/guest_speakers/tony.png',
          width: '360',
          height: '540',
          objectFit: 'cover'
        }
      },
      isFilled: false
    }
  ]
};
