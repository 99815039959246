import React from 'react';
import { useRouter } from 'next/router';
import config from '../utility/config';

const NotFound = () => {
  const router = useRouter();

  // If url doesn't exist, redirect to commuity ops.
  React.useEffect(() => {
    router.push(`${config.urlShortenerApi}/short-url${router.asPath}`);
  }, [router]);

  return null;
};

export default NotFound;
