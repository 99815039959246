import {
  CONTENTFUL_CAP_LANDING_PAGE_CONTENT_TYPE_ID,
  CONTENTFUL_COHORT_COURSE_CONTENT_TYPE_ID,
  CONTENTFUL_COMMUNITY_PRODUCT_TYPE_ID,
  CONTENTFUL_FEATURE_PAGE_CONTENT_TYPE_ID,
  CONTENTFUL_FREE_WORKSHOP_CONTENT_TYPE_ID,
  CONTENTFUL_USE_CASE_PAGE_CONTENT_TYPE_ID,
  DB_COMMUNITY_CONTENT_TYPE_ID,
  DB_EVENT_PUBLIC_PAGE_CONTENT_TYPE_ID,
  DB_RESOURCE_PUBLIC_PAGE_CONTENT_TYPE_ID
} from '@/utility/contentful/utils';

import AdminAuthContextProvider from '@/contexts/AdminAuthContext';
import { AuthContextProvider } from '@/contexts/AuthContext';
import NotFound from './404.page';
import dynamic from 'next/dynamic';
import RedirectPage from '@/components/common/RedirectPage/RedirectPage';

// const FreeWorkshop = dynamic(() => import('features/FreeWorkshop'));
// const CohortCoursePage = dynamic(() =>
//   import('features/CohortCoursePage')
// );
// const CommunityProductPage = dynamic(() =>
//   import('features/CommunityProductPage')
// );
const FeaturePage = dynamic(() => import('features/FeaturePage'));

const EventPublicPage = dynamic(() =>
  import('@/features/EventPublicPage')
);

// const CapLandingPage = dynamic(() =>
//   import('@/features/CapLandingPage/CapLandingPage')
// );

const ResourcePage = dynamic(() => import('@/features/ResourcePage'));

const CommunityMainContent = dynamic(() =>
  import('@/features/community/CommunityMainContent')
);

const UseCasePage = dynamic(() => import('@/features/UseCasePage'));

const LandingPageContent = (props) => {
  const { pageInfo, errorFetchingPage, pageExists } = props;
  const { templateContentType, templateData } = pageInfo;

  // Go on to short link service
  if (!pageExists || errorFetchingPage) {
    return <NotFound />;
  }

  switch (templateContentType) {
    // Removing all course pages from build for now aswell as old communityPage
    // case CONTENTFUL_COMMUNITY_PRODUCT_TYPE_ID:
    //   return (
    //     <StripeWrapper>
    //       <CommunityProductPage communityTemplateData={templateData} />
    //     </StripeWrapper>
    //   );
    // case CONTENTFUL_CAP_LANDING_PAGE_CONTENT_TYPE_ID:
    //   return (
    //     <StripeWrapper>
    //       <AuthContextProvider>
    //         <AdminAuthContextProvider>
    //           <CapLandingPage {...templateData} />
    //         </AdminAuthContextProvider>
    //       </AuthContextProvider>
    //     </StripeWrapper>
    //   );
    // case CONTENTFUL_COHORT_COURSE_CONTENT_TYPE_ID:
    //   return (
    //     <StripeWrapper>
    //       <AuthContextProvider>
    //         <AdminAuthContextProvider>
    //           <CohortCoursePage courseTemplateData={templateData} />
    //         </AdminAuthContextProvider>
    //       </AuthContextProvider>
    //     </StripeWrapper>
    //   );
    // case CONTENTFUL_FREE_WORKSHOP_CONTENT_TYPE_ID:
    //   return (
    //     <StripeWrapper>
    //       <FreeWorkshop courseTemplateData={templateData} />
    //     </StripeWrapper>
    //   );
    case CONTENTFUL_FREE_WORKSHOP_CONTENT_TYPE_ID:
    case CONTENTFUL_COMMUNITY_PRODUCT_TYPE_ID:
    case CONTENTFUL_CAP_LANDING_PAGE_CONTENT_TYPE_ID:
    case CONTENTFUL_COHORT_COURSE_CONTENT_TYPE_ID:
      // redirect to nasacademy.com as above pages are being phased out
      return <RedirectPage redirectLink={'https://nasacademy.com'} />;
    case CONTENTFUL_FEATURE_PAGE_CONTENT_TYPE_ID:
      return (
        <AuthContextProvider>
          <AdminAuthContextProvider>
            <FeaturePage courseTemplateData={templateData} />
          </AdminAuthContextProvider>
        </AuthContextProvider>
      );
    case CONTENTFUL_USE_CASE_PAGE_CONTENT_TYPE_ID:
      return <UseCasePage courseTemplateData={templateData} />;
    case DB_COMMUNITY_CONTENT_TYPE_ID:
      return (
        <AdminAuthContextProvider>
          <CommunityMainContent {...props} />
        </AdminAuthContextProvider>
      );
    case DB_EVENT_PUBLIC_PAGE_CONTENT_TYPE_ID:
      return (
        <AuthContextProvider>
          <AdminAuthContextProvider>
            <EventPublicPage {...props} />
          </AdminAuthContextProvider>
        </AuthContextProvider>
      );
    case DB_RESOURCE_PUBLIC_PAGE_CONTENT_TYPE_ID:
      return (
        <AuthContextProvider>
          <AdminAuthContextProvider>
            <ResourcePage {...props} />
          </AdminAuthContextProvider>
        </AuthContextProvider>
      );
    default:
      return <NotFound />;
  }
};

export default LandingPageContent;
